<template>
    <div>
        <v-toolbar style="background:#cad9e5;">
            <v-row>
                <v-btn
                    tile
                    color=""
                    style="margin-right:1%;"
                    @click="getDatas"
                    v-bind:disabled="this.moveDisabled==false"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    검색
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    
                    @click="dialog_move=true"
                    v-bind:disabled="this.moveDisabled==true"
                >
                    <v-icon left hidden>
                        mdi-plus-circle
                    </v-icon>
                    위치이동
                </v-btn>                
            </v-row>
        </v-toolbar>
        <v-toolbar style="background:#cad9e5;">
            <v-toolbar-items style="margin-right:1%; margin-top:3%;" >
                <v-text-field
                    v-model="lot_code"
                    id="wearing-item_code"
                    label="LOT번호"
                    clearable
                    v-on:lot_code_update="onTextChangne2('lotno', $event)"
                    @change="value => onTextChangne('lotno', value)"
                    @input="onTextChangne('lotno', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                    ref="lotno_val"
                ></v-text-field>

            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="wearing-item_code"
                    label="품번"
                    clearable
                    @change="value => onTextChangne('item_code', value)"
                    @input="onTextChangne('item_code', $event)"
                    @keyup="getDatas"
                    @keypress="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="wearing-item_name"
                    label="품명"
                    clearable
                    @change="value => onTextChangne('item_name', value)"
                    @input="onTextChangne('item_name', $event)"
                    @keyup="getDatas"
                    @keypress="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="wearing-bin_code"
                    label="위치정보"
                    clearable
                    @change="value => onTextChangne('bin_code', value)"
                    @input="onTextChangne('bin_code', $event)"
                    @keyup="getDatas"
                    @keypress="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            ref="dataTable"
            :headers="headers"
            :items="datas"
            class="elevation-1"
            show-select
            item-key="id"
            @click:row="onClickRow"
            height="100%"
            fixed-header
            dense
            single-select
                
            :items-per-page="perPage"
            :footer-props="footerProps"
            :page.sync="page"
            :server-items-length="dataCounts"
            :options.sync="options"
        >
            <template v-slot:[`item.quan`]="props">
                <div style="backgroundColor:#f5f5b5; width:100%; height:100%; padding-top:5%;">
                    <v-edit-dialog
                        :return-value.sync="props.item.quan"
                        save-text="확인"
                        cancel-text="취소"
                        large
                        @save="save(props.item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                        @keypress="disabledSelectAll"
                    >
                        {{ Number(props.item.quan).toLocaleString()  }}

                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.quan"
                            label="Edit"
                            single-line
                            counter
                            type="number"
                            @focus="$event.target.select()"
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </div>
            </template>
        </v-data-table>

        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="dialog_move"
            width='90%'
            max-width="1000px"
            scrollable
        >
            <div style="width:1000px; height:600px; background:white;">
                <div id="cards" style="width:100%; height:530px; overflow-y: scroll;">
                    <v-card
                        v-for="(card) in moveCards"
                        :key="card.unique_id"
                        style="margin:3%;"
                    >
                        <v-card-title style="width:1000px;height:80px">
                            <v-card-text style="width:500px;">{{ card.item_code + ' / ' + card.item_name }}</v-card-text>
                            <v-card-text style="width:100px;"> {{ card.bin_code }} </v-card-text>
                            <v-card-text style="width:100px;"> {{ card.lotno }} </v-card-text>
                            <v-card-text style="width:100px;"> {{ card.quan_old }} </v-card-text>
                            <v-edit-dialog
                                ref="aaaa"
                                :return-value.sync="card.quan"
                                large
                                save-text="확인"
                                cancel-text="취소"
                                @save="save"
                                @cancel="cancel"
                            >                              
                                <v-btn class="mx-1" color="#46B2E1" 
                                :class="{ 'red--text': card.quan_old - card.quan < 0 }" 
                                >
                                    {{card.quan * 1}}
                                </v-btn>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="card.quan"
                                        label="Edit"
                                        single-line
                                        counter
                                        type="number"
                                        @focus="$event.target.select()"
                                        @change="checkQuantity(card.quan_old-card.quan)"
                                    ></v-text-field>
                                    
                                </template>
                            </v-edit-dialog>
                            <v-col style="width:100px;" 
                               :class="{ 'red--text': card.quan_old - card.quan < 0 }"
                            >
                                {{ card.quan_old - card.quan }} 
                            </v-col>                         
                        </v-card-title>
                    </v-card>
                </div>

                <div id="btns" style="width:100%; height:50px;">
                    <v-row>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="primary"
                                @click="dialog_bin=true"
                                :disabled="isDisabled"
                                @mousedown="resetAddForm"
                            >이동
                            </v-btn>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="error"
                                @click="dialog_move=false"                                
                            >취소
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialog_bin"
            width="50%"
            max-width="500px"
            scrollable
        >
            <v-card style="width:500px; height:200px; background:white;">
                <v-card-title>
                    <span class="text-h5">입고</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col cols="12" sm="12">
                            <v-autocomplete
                                label="위치검색"
                                v-model="locationModel"
                                :items="locationItems"
                                :search-input.sync="searchLocation"
                                clearable
                                item-text="bin_code"
                                item-value="bin_code"
                                return-object
                                hide-details
                                hide-selected
                                @change="clickLocation"
                                @click="searchLocation_void"                                
                            ></v-autocomplete>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        tile
                        @click="stockMoveHandler"
                    >
                        확인
                    </v-btn>
                    <v-btn
                        color="error"
                        tile
                        @click="dialog_bin = false"
                    >
                        취소
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>

        <v-dialog
            v-model="dialogCheck"
        >
            <v-card>
                <v-card-title>{{ textCheck }}</v-card-title>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Release",
  components: {
    
  },
  data() {
    return {
        headers: [
            { value:'lotno', text:'LOT번호', width:'10%', align:'start', sortable: true, },
            { value:'item_code', text:'품번', width:'15%', align:'start', sortable: true, },
            { value:'item_name', text:'품명', width:'15%', align:'start', sortable: true, },
            { value:'specification', text:'규격', width:'10%', align:'start', sortable: true, },
            { value:'detail_info', text:'상세사양', width:'10%', align:'start', sortable: true, },
            { value:'quan', text:'수량', width:'10%', align:'center', sortable: true},
            { value:'bin_code', text:'위치정보', width:'10%', align:'center', sortable: true, },
            { value:'last_in_date', text:'최근입고일', width:'10%', align:'center', sortable: true, },
            { value:'last_out_date', text:'최근출고일', width:'10%', align:'center', sortable: true, },
        ],
        selected:[],
        datas:[],
        dataCounts:0,
        perPage:100,
        page:1,
        footerProps: { 'items-per-page-options': [100, -1] },
        options:{},
        params:{},

        isDisabled:null,
        orderBy:null,
        order_desc:null,

        lot_code:'',
        lotno:null,
        set_lotno:null,

        moveDisabled:true,

        overlay: false,
        dialog_adjust:false,
        dialog_move:false,
        dialog_bin:false,
        dialogCheck:false,
        textCheck:'',

        snack:false,
        snackColor:'',
        snackText:'',

        moveCards:[],

        locationModel:null,
        locationItems:[],
        searchLocation:null,
        selectedLocation:null,

        itemCode:'',

        flag_err:null,

        moveRules: {
            check: (quan, quan_edit) => {
                if(quan!=null && quan!=undefined && 
                    quan_edit!=null && quan_edit!=undefined) {
                    if(quan < quan_edit) {
                        return true || '현재수량 적은 값만 가능합니다.';
                    }
                }

                return true;
            }
        }
    };
  },
  mounted() {
    this.resetAddForm();
    //this.init();    
  },
  watch: {
    dialog_move() {
        if(this.dialog_move) {
            if(this.selected!=null && this.selected.length>0) {
                this.moveCards = [];
                for(var i=0; i<this.selected.length; i++) {
                    let select = this.selected[i]
                    //console.log(select.uid_item)
                    let obj = {
                        unique_id:select.unique_id,
                        uid_item:select.uid_item,
                        item_code:select.item_code,
                        item_name:select.item_name,
                        specification:select.specification,
                        bin_code:select.bin_code,
                        quan_tot:0,
                        quan_subt:0,
                        quan_result:0,
                        quan_old:select.quan,
                        quan:select.quan,
                        lotno:select.lotno,
                        set_lotno:select.lotno
                    }
                    this.moveCards.push(obj)
                }
            }
        }
    },

    selected() {
        if(this.selected.length>0) {
            this.moveDisabled = false;
        } else {
            this.moveDisabled = true;
        }
    },

    searchLocation(val) {
        if(val == null || val.length<1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      },
      
     options(val) {
        var curPage = val.page;
        var itemsPerPage = val.itemsPerPage;
        if(itemsPerPage!=null && itemsPerPage==-1) {
            itemsPerPage = 100000000;
        }
        this.page = curPage;
        this.perPage = itemsPerPage;

        if(val.sortBy[0]){
            this.orderBy = "&orderBy=" + val.sortBy[0];
            this.order_Desc = "&isAsc=" +  val.sortDesc[0];
        }
        else{
            this.orderBy=null;
            this.order_Desc=null;
        }

        this.getDatas();
     }
  },
  methods: {
    init() {
        this.getDatas()
    },
    checkQuantity(val) {

      this.isDisabled = val< 0;
    },
    searchLocation_void() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
    },

    resetAddForm() {
        this.search_item = null
        this.cards = null
        this.selectedItem = null
        this.lotno = null;
        this.search_item = null;   
        this.selected = [];
        this.lot_code=null;
        this.locationItems=[];
    },  
    chk_move(a,b){
        this.flag_err=(a-1)<0?1:0;
    },
    //##DBG 추가
    disabledSelectAll(){
        const selectedItems = this.$refs.dataTable.selection;

        for(var key in selectedItems) {
            selectedItems[key] = false;
            key = null;
        }
        this.selected = [];
    },

    checkSave(select) {
        const quan = select.quan;
        const quan_edit = select.quan_edit;

        if(quan!=null && quan!=undefined && quan_edit!=null && quan_edit!=undefined) {
            if(quan < quan_edit) {
                select.quan = quan;
                this.dialogCheck = true
                this.textCheck = '현재수량 보다 적어야 합니다.'
            } else {
                select.quan = quan_edit;
            }
        }
    },
    getDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocation';
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += this.orderBy="&orderBy=unique_id";
            url += this.order_Desc="&isAsc=false";            
        }
        
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.result;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count;
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            
          })
    },
    onClickRow(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }
    },

    onTextChangne(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        } else {
            this.params[key] = val;
        }
    },

    stockMoveExcelUpload() {
        let input = document.createElement('input')

        input.id = 'excel'
        input.type = 'file'
        input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        input.multiple = true

        input.click();

        var url = this.$vBACKEND_URL + '/template.do?method=execStockMoveExcel'
        var me = this

        // Event
        input.onchange = function() {
            me.overlay = true
            const formData = new FormData()
            formData.append('file', this.files[0])
            formData.append('uid_company', me.$store.getters.getUidCompany)
            formData.append('user_uid', me.$store.getters.getUserUid)
            formData.append('user_id', me.$store.getters.getUserId)
            formData.append('user_name', me.$store.getters.getUserName)

            axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                        me.snackbar = true;
                        me.snackResult = '#60C5F1';
                        me.snackText = '성공'

                        me.getDatas();
                    }).catch(err => {
                        console.log(err)
                        me.snackbar = true;
                        me.snackResult = "red";
                        me.snackText = '실패'
                    }).finally(() => {
                        me.overlay = false
                    })
        }
    },

    stockMoveExcelTemplate() {
        this.overlay = true
        var url = this.$vBACKEND_URL + '/template.do?method=stockMoveExcelTemplateDown';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in obj) {
        params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;

            if(excelPath!=null) {
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath
            } 
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    },

    save(item) {
        if(item==null || item.length<1) return;

        let unique_id = item.unique_id
        let quan = item.quan

        if(unique_id == null || unique_id<1 || quan == null) {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = '수량변경 실패'
            return
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=adjustLocationQuan';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            uid_locations:unique_id,
            location_quans:quan,
        }

        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
        .then(res => {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = '수량변경 완료'
        })
        .catch(err => {
            console.log(err)
        })
    },

    cancel() {

    },

    open() {

    },

    close() {

    },

    clickLocation(obj) {
        const bin_code = obj.bin_code
        this.locationModel = bin_code
        this.selectedLocation = obj
    },

    stockMoveHandler() {
        console.log('=== stockMoveHandler', this.moveCards)

        if(this.moveCards==null || this.moveCards.length<1) {
            this.snack = true
            this.snackText = '선택한 항목을 확인해주세요'
            this.snackColor = 'red'
            return;
        }

        let location_uids = [];
        let location_quans = [];
        let location_quans_old = [];
        let lotnos = [];
        let set_lotnos = [];
        let item_codes = [];

        for(var i=0; i<this.moveCards.length; i++) {
            let card = this.moveCards[i]
            let location_uid = card.unique_id
            let location_quan = card.quan
            let location_quan_old = card.quan_old
            let item_code = card.item_code
            let uid_item = card.uid_item
            location_uids.push(location_uid)
            location_quans.push(location_quan)
            location_quans_old.push(location_quan_old)
            lotnos.push(card.lotno)
            set_lotnos.push(card.lotno)
            item_codes.push(card.uid_item)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execMove';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          location_uids:location_uids,
          location_quans:location_quans,
          location_quans_old:location_quans_old,
          bin_uid:this.selectedLocation.unique_id,
          lotnos:lotnos,
          set_lotnos:set_lotnos,
          item_codes:item_codes,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;
        //console.log(url);
        


        fetch(url)
            .then(res => res.clone().json())
            .then((res) => {
                var result = res.result

                
                //console.log(res.result);
                if(result=="4"){
                    this.snack = true
                    this.snackColor = "red"
                    this.snackText = "위치이동 실패(위치관리에 위배되는 품번이 있습니다.)"    
                    alert(this.snackText);
                }else{
                    this.snack = true
                    this.snackColor = "green"
                    this.snackText = "위치이동 성공"
                }
            })
            .catch(err => {
                this.snack = true
                this.snackColor = "red"
                this.snackText = "위치이동 실패"
                console.log(err)
            })
            .finally(() => {
                this.dialog_bin = false;
                this.dialog_move = false;

                this.moveCards = [];
                this.selectedLocation = null;

                this.getDatas();
            })
    }
  }
};
</script>

<style>
.red--text{
    color:red;
}
.v-data-table__wrapper {
    height:72vh !important;
}
tr.v-data-table__selected {
    color: rgb(251, 251, 248);
    background: #7d92f5 !important;
}
</style>